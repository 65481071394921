@import '../variables';

.main-wrapper{
    font-family: $body-font;
    color: white;
    line-height: 1.4;

    .key-container{
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 75%;
        }
    }

    .you-win.stq-form-container {
        text-align: left;
        margin: auto;

        .MuiInputBase-root, .MuiFormLabel-root {
            font-family: $body-font;
        }
    }

    .you-win{
        text-align: center;
        padding-top: 100px;

        h1{
            font-family: $heading-font;
            text-transform: uppercase;
            margin-bottom: .5em;
        }

        h3 { font-family: $body-font;
            font-weight: normal;
            font-style: normal; }

        > img {
            max-width: 66%;
            margin: 1em auto;
        }

        p{
            margin-bottom: 30px;
            // text-align: left;

            a{
                color: $white;
                text-decoration: underline;
            }
            &.legal {
                font-size: .75em;
            }
        }

        small{
            margin-bottom: 20px;
            display: inline-block;
            color: #f7d587;
        }

        span{
        }

        .social-container{
            margin-bottom: 20px;
            
            img{
                margin: 0 10px;
                max-width: 44px;
            }
        }

        .download-btn{
            margin-bottom: 135px;
        }


        .MuiTextField-root{
            max-width: 355px;
            width: 100%;
            margin-bottom: 25px;

            &.error{
                .MuiInputBase-root{
                    border: 2px solid #d00;
                }
            }

            .MuiFormHelperText-root{
                display: block;
                position: relative;
                margin: 0;
                padding: 4px;
                background: #dd0000;
                color: white;
                font-family: $body-font;
                margin-top: -6px;
                z-index: 6;
                border-bottom-right-radius: 3px;
                border-bottom-left-radius: 3px;
                padding-left: 30px;

                &:before{
                    position: absolute;
                    content: "";
                    top: 50%;
                    left: 7px;
                    width: 16px;
                    height: 16px;
                    // background: url(../../images/error-symbol.png) no-repeat center center;
                    transform: translateY(-50%);
                }
            }

            label{
                transform: translate(12px, 22px) scale(1);

                &.MuiInputLabel-shrink{
                    transform: translate(12px, 8px) scale(.75);
                }

                &.Mui-focused{
                    color: rgb(122, 122, 122);
                }
            }

            .MuiInputBase-root{
                background: white;
                border-radius: 8px;
                border: 2px solid transparent;

                &.Mui-focused{
                    border: 2px solid $white;
                }

                .MuiFilledInput-input{
                    padding: 27px 12px 10px 0;
                    text-indent: 12px;
                }
            }

            .MuiFilledInput-underline{
                &:after{
                    display: none;
                }

                &:before{
                    display: none;
                }
            }
        }

        &.lose {
            h3 {
                margin-top: 2em;
                margin-bottom: 2em;
            }
        }
    }
}