@import '../variables';

.main-wrapper{

    .row {

        .home-text-container{ 
            .heading-text {
                text-transform: uppercase;
                font-size: 1.1em;

                h1 { line-height: 1; margin: 0; }

                .unlock-the-secret {
                    font-size: 1em;
                }

                .prize-money {
                    font-size: 2em;
                    sup {
                        font-size: .5em;
                        vertical-align: .8em;
                        top: 0;
                    }
                    small {
                        font-size: .7em;
                        font-weight: 700;
                    }
                    @media screen and (min-width:768px) {
                        max-width: 60%;
                        margin: 0 auto;
                    }
                }

                .plus-cash {
                    font-size: 1.6em;
                    font-family: $body-font;
                    font-style: normal;
                    font-weight: normal;
                    text-transform: none;;
                }

                .logos {
                    span { display: inline-block; }
                    span img { width: 50%; height: auto; }
                    @media screen and (max-width: 767.9px) {
                        display: flex;
                    }
                }

                margin-bottom: 2em;
            }

            .home-header {
                font-family: $heading-font;
                text-align: center;
                margin: 30px 0;
                text-transform: uppercase;

            }

            .home-body-text{
                font-family: $body-font;
                font-size: 1.6em;
                text-align: center;
                font-weight: normal;
                // margin: 1em 0;

                a { color: $white; }
                @media screen and (min-width:768px) {
                    max-width: 60%;
                    margin: 0 auto;
                }
            }

            .learn-more-container {
                font-family: $body-font;
                text-align: center;
                margin: 2em 0;

                background-image: url(../../images/chevron.png);
                background-repeat: no-repeat;
                background-position: bottom center;
                background-size: 32px auto;
                padding-bottom: 40px;

                a, a:visited, a:hover {
                    font-size: 1em;
                    color: #fff;
                    text-decoration: none;
                }
            }

            .insta-link {
                width: 2em;
                height: 2em;
            }
            
        }
        &#howtoenter {
            text-align: center;
            margin-bottom: 2em;

            div {
                img {
                    height: 150px;
                    object-fit: contain;
                    margin-bottom: 1em;
                    &.overflow {
                        margin-left: -1em;
                        margin-right: -1em;
                        margin-top: -1em;
                        margin-bottom: 0em;
                        height: calc(150px + 2em);
                    }
                }
                @media screen and (max-width: 767.9px) {
                    margin-bottom: 2em;
                }
            }

            h2, h3 {
                text-transform: uppercase;         
            }
            h2 {
                margin-bottom: 1em;
            }
            h3 {
                font-size: 1em;
                margin-bottom: .5em;
            }

            p {
                max-width: 500px;
                margin: 0 auto;
                small {
                    display: block;
                }
            }
        }

        
    }

    .container.disclaimer-wrap { padding-bottom: 3em; }
    .disclaimer {

        p {
            a{
                color: inherit;
                text-decoration: underline;
            }
        }
    }
}

//french version
.fr-CA{
    .main-wrapper .full-Vh .home-text-container .heading-text,
    .main-wrapper .full-Vh .home-text-container .heading-text .unlock-the-secret,
    .main-wrapper .full-Vh .home-text-container .heading-text .chance-to-win{
        

        @media screen and (max-width: 460px) {
            
        }
    }

    .main-wrapper .full-Vh .home-text-container .heading-text .unlock-win{
        @media screen and (max-width: 460px) {
            
        }
    }

    .main-wrapper .full-Vh .home-text-container .enter-btn-container button p{

        width: 100%;
    }

    .main-wrapper .full-Vh .home-text-container .heading-text .chance-to-win{
        @media screen and (max-width: 460px) {

        }
    }

    .main-wrapper .full-Vh .home-text-container .heading-text .prize-money{
        @media screen and (max-width: 460px) {

        }
    }
    .main-wrapper .row {
        .coming-soon-text-container, .home-text-container {
            .heading-text {
                .prize-money {
                    @media screen and (max-width: 1199px) {
                    }

                    @media screen and (max-width: 991px) {
                    }

                    @media screen and (max-width: 460px) {
                    }
                }
                .plus-cash {
                    @media screen and (max-width: 1199px) {
                    }
                    @media screen and (max-width: 991px) {
                    }
                    @media screen and (max-width: 460px) {
                    }
                }
            }
        }
    }
}


// Playa graphic.
.playa-wrapper {
    position: relative;
    padding-bottom: 66.7%;
    h2.gameon {
        position: absolute;
        top: 1em;
        width: 100%;
        text-align: center;
        z-index:111;
        font-size: 2em;
    }
    .screens {
        position: absolute;
        max-width: 100%;
    }
    .gamer {
        max-width:  100%;
        position: absolute;
    }

    
    @media screen and (min-width: 768px) {
        max-width: 600px;
        margin: 0 auto;
        padding-bottom: 33%;
        h2.gameon {
            top: 1.5em;
            font-size: 4em;
        }
    }
}
