@import './fonts.scss';

$grey : #656565;
$white : #ffffff;
$black: #000000;
$darkblue: #000820;
$blue: #002158;
$lightblue: #355ADC;
$cyan: #5CE5F7;
$purple: #841DD0;
$green: #1fba23;
$transblue: #0018609e;
$transgreen: #1fba2384;

//fonts
$heading-font : "good-times", sans-serif;
$body-font : "plau", sans-serif;