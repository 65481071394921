@import './variables';

body{
    font-family: sans-serif;
    background: $darkblue;
}

.main-wrapper{
    margin-top: 64px;
    padding-top: 64px;
    position: relative;
    z-index: 1;
    min-height: calc(100vh - 120px);
    .container { 
        padding-bottom: 6em;

        &:not(.home, .info-form__container, .pin-form__container, .win) {
            padding-top: 3em;
        }
    }
}

#bg {
    //overflow:hidden;
    width: 100%;
    height: 100vh;
    min-height: 700px;
    position: absolute;
    z-index:-99;
    background-image: url('../images/desktop-background.jpg');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;

    //top:25px;
    // overflow: hidden;

    @media screen and (max-height: 700px) {
        min-height: 400px;
    }

    > *{
        // overflow: hidden;
    }
}

.enter-btn, .green-btn {
    border-radius: 3em;
    line-height: 2em;
    width: 300px;
    font-family: $heading-font;
    font-weight: 700;
    font-size: 2em;
    font-style: italic;
    display: inline-block;
    color: $white;
    box-sizing: border-box;
    position: relative;
    transition: background-color .3s ease;
}

.enter-btn {
    background-color: $darkblue;
    background-clip: padding-box;
    border: solid 2px transparent;
    &:before {
        content: '';
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        z-index: -1;
        margin: -2px;
        border-radius: inherit;
        background: linear-gradient(to right, $cyan, $purple);
    }
    
    &:hover {
        color: $white;
        background-color: #00000000;
        text-decoration: none;
        filter: drop-shadow(0px 0px 6px $white);
    }
}
.green-btn {
    background-color: $transgreen;
    border: none;
    &:hover {
        color: $white;
        background-color: $green;
    }
    &[disabled] {
        background-color: #65656565;
        pointer-events: none;
    }
}

.fr-CA {
    .enter-btn, .green-btn {
        font-size: 1em;
        line-height: 4em;
    }
}